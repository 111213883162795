import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { PortableText, PortableTextProps } from '@portabletext/react'

import stars from '../../assets/pngs/stars.png'
import starsAlt from '../../assets/pngs/starsAlt.png'
import dots from '../../assets/pngs/dots.png'
import SanityWithInAction from '../shared/SanityWithInAction';

type tabData = Queries.SanityAlternativeHeroComponentFragment['tabs'][number];

const tabComponent = (data: tabData, selected = false, makeActive) => {
  const className = `tab${selected ? ' selected' : ''}`;
  if (!data?.feature?.icon?.image?.asset?.gatsbyImageData) return(<div />);
  return (<div className={className} onClick={makeActive} onKeyDown={makeActive} role="button" tabIndex={0}>
    <GatsbyImage alt='icon' image={data.feature.icon.image.asset.gatsbyImageData} />
    <p>{data.feature.title}</p>
  </div>)
}

const optionComponent = (data: tabData, index) => (
  <option value={index}>{data.feature.title}</option>
)

const tabSection = (tab: tabData) => {
  const linkText = tab.linkTextOverride || 'Learn More';
  if (!tab.additionalLink) return (<Link to={tab.feature.url.current}>{linkText}</Link>);
  return (
    <div>
      <p>
        <GatsbyImage alt='icon' image={tab?.feature?.icon?.image?.asset?.gatsbyImageData} /><Link to={tab.feature.url.current}>{linkText}</Link>
      </p>
      <p>
        <GatsbyImage alt='icon' image={tab.additionalLink?.icon?.image?.asset?.gatsbyImageData} /><Link to={tab.additionalLink.url.current}>{tab.additionalLink.title}</Link>
      </p>
    </div>
  )
}

const tabContentComponent = (tab: tabData, selected = false) => (
  <div hidden={!selected} className='content'>
    <div className='text'>
      <h2>{tab.innerTitle || tab.feature.title}</h2>
      <p>{tab.descriptionOverride || tab.feature.description}</p>
      {tabSection(tab)}
    </div>
    <div className='image'>
      {/* <img alt='icon' src='https://cdn.sanity.io/images/mr2nzgx7/development/2bbda3ac3019d03d434b6cbb344f6e72c7ba7daf-4936x3221.png?'/> */}
      <GatsbyImage alt='icon' image={tab.image?.asset?.gatsbyImageData}/>
    </div>
  </div>
)

const portableText = ({_rawDescription: rawDescription, _id: id}: Queries.SanityTextBlockFragment) => (
  <PortableText key={id} value={rawDescription as unknown as PortableTextProps['value']} />
);

const SanityAlternativeHeroComponentBlock = ( { companyLogos, action, tabs, text }: Queries.SanityAlternativeHeroComponent) => {
  const [selectedIndex, setIndex] = useState(2);
  const selectedTab = tabs[selectedIndex];
  const company = (<>{companyLogos && <div className='heroBanner-secondary'>
    <div className='header-wrapper'>
      <div className='row'>
        <div className='col-md-12'>
          <div className='sponsors'>
            <p>{companyLogos.cardTitle}</p>
            <div className='sponsors_list d-flex flex-row flex-wrap justify-content-between'>
              {companyLogos.icons.map((item) => (
                <GatsbyImage className='col' image={item?.image?.asset?.gatsbyImageData} alt='logo' style={{ objectFit: 'contain' }} key={item.id} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>}</>)
  return (
    <div className='heroWrapper'>
      <div className='alternativeBanner'>
        <img alt="stars" src={stars} className='background upper-left'/>
        <img alt="dots" src={dots} className='background lower-left'/>
        <img alt="dots" src={dots} className='background upper-right'/>
        <img alt="stars" src={starsAlt} className='background lower-right'/>
        {/* textWrap is not yet present in the react type definitions apparently ... */}
        <div style={{ textWrap: "balance" } as any}>
        {text.map(portableText)}
        </div>
        <div className="topActionButtons">
          {action.map(actionComponent => <SanityWithInAction key={actionComponent.id} {...actionComponent}/>)}
        </div>
        <div className='tabContainer'>
          <div className='tabs'>
            {tabs.map((tab, index) => tabComponent(tab, index === selectedIndex, () => setIndex(index)))}
          </div>
          <div className='select'>
            <GatsbyImage alt='icon' image={selectedTab?.feature?.icon?.image?.asset?.gatsbyImageData} />
            <select value={selectedIndex} onChange={e => setIndex(parseInt(e.target.value, 10))}>
              {tabs.map((tab, index) => optionComponent(tab, index))}
            </select>
          </div>
          <div className='tabBody'>
            <img alt="stars" src={stars} className='background mid-left'/>
            {tabs.map((tab, index) => tabContentComponent(tab, index === selectedIndex))}
          </div>
        </div>
        {company}
      </div>
    </div>
  )
};

export default SanityAlternativeHeroComponentBlock;

export const query = graphql`
fragment SanityAlternativeHeroComponent on SanityAlternativeHeroComponent {
  id
  internalName
  action {
    ...SanityActionComponent
  }
  tabs {
    innerTitle,
    descriptionOverride,
    image {
      asset {
            gatsbyImageData(placeholder: NONE)
      }
    }
    feature {
      icon {
        image {
          asset {
            gatsbyImageData(width: 40, placeholder: NONE)
          }
        }
        alt
      }
      title
      url {
        current
      }
      description
    }
    linkTextOverride
    additionalLink {
      icon {
        image {
          asset {
            gatsbyImageData(width: 40, placeholder: NONE)
          }
        }
        alt
      }
      title
      url {
        current
      }
    }
  }
  companyLogos {
    icons {
      image {
        asset {
          gatsbyImageData(placeholder: NONE)
        }
      }
      id
    }
    cardTitle
  }
  text {
    ...SanityTextBlock
  }
}
`

