import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import iconTopCorner from '../../assets/svgs/icon-top-corner.svg';
import iconBottomCorner from '../../assets/svgs/icon-bottom-corner.svg';
import SanityWithInAction from '../shared/SanityWithInAction';
import SanityPortableText from '../shared/SanityPortableText';

const SanityCardsCarouselBlock = ({ cardData, backgroundColor, button }: Queries.SanityCardsCarousel) => {
  const [currentIndex, setIndex] = useState(0);
  return (
    <div className='session_carousel' style={{ backgroundColor: `${backgroundColor?.hex}`, opacity: backgroundColor?.alpha }}>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='accordion' >
              <div className='accordion_img'>
                {currentIndex ? (
                  <GatsbyImage
                    image={getImage(cardData[currentIndex].image?.asset?.gatsbyImageData)}
                    className='image img-fluid'
                    alt={cardData[currentIndex]?.internalName}
                  />
                ) : <>{currentIndex === 0 && <GatsbyImage
                  image={getImage(cardData[0].image?.asset?.gatsbyImageData)}
                  className='image img-fluid'
                  alt={cardData[currentIndex]?.internalName}
                />}</>}
              </div>
              <div className='accordion_slides'>
                {cardData.map((data, index) => (
                  <div className={
                    index === currentIndex ? 'slide active' : 'slide'
                  } key={data.id}>
                    <button
                      className='plain-button text-left'
                      onClick={() => setIndex(index)}
                      type='submit'
                    >
                      <SanityPortableText {...data?.cardTitle} />
                    </button>
                    {index === currentIndex && <>
                      {data.text.map(({ _id: id, ...texts }) => <div key={id}> <SanityPortableText _id={id} {...texts} /></div>)}
                      {data.action && <SanityWithInAction {...data.action} />}
                    </>
                    }
                    <img
                      src={iconTopCorner}
                      className='TopCorner'
                      alt='TopCorner'
                    />
                    <img
                      src={iconBottomCorner}
                      className='BottomCorner'
                      alt='BottomCorner'
                    />
                  </div>
                ))}
                <SanityWithInAction {...button} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SanityCardsCarouselBlock

export const query = graphql`
fragment SanityCardsCarousel on SanityCardsCarousel {
  id
  backgroundColor {
    hex
    alpha
  }
  button {
    ...SanityActionComponent
  }
  cardData {
   ...SanityCardDataFragment
  }
}
`
