import * as React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import SanityPortableText from '../shared/SanityPortableText';
import SanityWithInAction from '../shared/SanityWithInAction';
import sideImageRight from '../../assets/svgs/thin-dots-right.svg';
import sideImageLeft from '../../assets/svgs/thin-dots-left.svg';
import { breakpoints } from '../../styles/pages/breakpoints';

const Section = styled.section`
position:relative;
&::before{
  content:'';
  background-image: url(${sideImageLeft});
  position: absolute;
  left: -2%;
  top: 33%;
  width: 17%;
  z-index: 0;
  height: 50%;
  background-repeat: no-repeat;
}
&::after{
  content:'';
  background-image: url(${sideImageRight});
  position: absolute;
  right: -2%;
  top: 33%;
  width: 7%;
  z-index: 0;
  height: 50%;
  background-repeat: no-repeat;
}
.plain{
  .long-arrow-right {
    margin: 3px auto;
  }
}
@media (max-width: ${breakpoints.lg}) {
  &::before{
    width: 5%;
    height: 100%;
    left: -1%;
  }
  &::after{
    width: 5%;
    height: 100%;
    right:-1%
  }
}
@media (max-width: ${breakpoints.md}) {
  &::before{
    display: none;
  }
  &::after{
    display: none;
  }
}
@media (min-width: 1600px) {
  max-width: 1580px !important;
    margin: 0 auto;

}
`

const SanityIntegrationBlock = ({ integrationTitle, text, button, mobileImage, desktopImage }: Queries.SanityIntegration) => (
  <Section className='integration'>
    <div className='header-wrapper'>
      <div className='title'>
        {integrationTitle.map(({ _id: id, ...texts }) => (
          <div key={id}>
            <SanityPortableText _id={id} {...texts} />
          </div>
        ))}
      </div>
      {desktopImage && <GatsbyImage className='d-none d-md-block mt-4' image={desktopImage?.image?.asset?.gatsbyImageData} alt={desktopImage?.alt || 'integrationImage'} style={{ zIndex: 1 }} />}
      {mobileImage && <GatsbyImage className='d-block d-md-none' image={mobileImage?.image?.asset?.gatsbyImageData} alt={mobileImage?.alt || 'integrationImage'} style={{ zIndex: 1 }} />}
      <div className='d-none d-md-flex flex-row justify-content-around mx-5 mb-5'>
        {text.map(({ _id: id, ...texts }) => (
          <div className='row-text' key={id} >
            <SanityPortableText _id={id} {...texts} />
          </div>
        ))}
      </div>
      {button && <div className='mt-3 mt-md-5'><SanityWithInAction {...button} /></div>}
    </div>
  </Section>)
export default SanityIntegrationBlock

export const IntegrationQuery = graphql`
fragment SanityIntegration on SanityIntegration {
  id
  integrationTitle {
    ...SanityTextBlock
  }
  text {
    ...SanityTextBlock
  }
  button {
    ...SanityActionComponent
  }
  desktopImage {
    image {
      asset {
        gatsbyImageData(placeholder: NONE)
      }
    }
    alt
  }
  mobileImage {
    image {
      asset {
        gatsbyImageData(placeholder: NONE)
      }
    }
    alt
  }
}
`
