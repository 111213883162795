import React, { useState } from 'react';
import { Link, graphql } from 'gatsby';
import PortableText from '@sanity/block-content-to-react';
import { GatsbyImage } from 'gatsby-plugin-image';
import SanityWithInAction from '../shared/SanityWithInAction';
import logo from '../../assets/svgs/logrocket-logo.svg';

const tagComponent = ({ id, title }: Queries.SanityCustomerTag) => (
  <div className='tag' key={id}>{title}</div>
);

const QuoteCard = ({ id, _rawFeedback: rawFeedback, tags, listingImage, listingTitle, actionComponent }: Queries.SanityQuotes) => (
  <div className="quoteCard">
    {listingImage ? <GatsbyImage alt='icon' image={listingImage.asset.gatsbyImageData} /> : null}
    <div className="content">
      <h2>{listingTitle}</h2>
      <div className="quote">
        <PortableText key={id} blocks={rawFeedback} />
      </div>
      <div className="tags">
        {tags.map((tag) => tagComponent(tag))}
      </div>
      <div className='button'>
        <SanityWithInAction {...actionComponent} />
      </div>
    </div>
  </div>
);

const getUsedFilters = (data: Readonly<Queries.SanityQuotes[]>, filterType) => [ ...new Set(data.map(
  quote => quote.tags.filter(tag => tag.filterType === filterType)
).flat().map(({ title }) => title))];

const optionComponent = (data: string) => (
  <option>{data}</option>
)

const selectSetter = (tags: string[], setter: React.Dispatch<string>) => (event: React.ChangeEvent<HTMLSelectElement>) => {
  const { selectedIndex } = event.target as HTMLSelectElement;
  if (!selectedIndex) return setter(null);
  return setter(event.target.value);
}

const SanityCustomerListBlock = ({ quotes }: Queries.SanityCustomerList) => {
  const [industryFilter, setIndustryFilter] = useState(null);
  const [useCaseFilter, setUseCaseFilter] = useState(null);
  const [personaFilter, setPersonaFilter] = useState(null);

  const resetFilter = (e) => {
    setIndustryFilter(0);
    setUseCaseFilter(0);
    setPersonaFilter(0);
    e.currentTarget.blur()
  }

  const industryTags = ['All Industries', ...getUsedFilters(quotes, 'industry')];
  const useCaseTags = ['All Use Cases', ...getUsedFilters(quotes, 'useCase')];
  const personaTags = ['All Personas', ...getUsedFilters(quotes, 'persona')];

  const filters: string[] = [industryFilter, useCaseFilter, personaFilter].filter(Boolean);
  const toRender = quotes.filter(({ tags }) => filters.every(filter => tags.map(x => x.title).includes(filter)));
  return (
    <div className='customerListWrapper'>
      <div className="customerList">
        <h1>Customer stories</h1>
        <div className="filters">
          <div className='select'>
            <select value={industryFilter} onChange={selectSetter(industryTags, setIndustryFilter)}>
              {industryTags.map((tab) => optionComponent(tab))}
            </select>
          </div>
          <div className='select'>
            <select value={useCaseFilter} onChange={selectSetter(useCaseTags, setUseCaseFilter)}>
              {useCaseTags.map((tab) => optionComponent(tab))}
            </select>
          </div>
          <div className='select'>
            <select value={personaFilter} onChange={selectSetter(personaTags, setPersonaFilter)}>
              {personaTags.map((tab) => optionComponent(tab))}
            </select>
          </div>
          <button onClick={resetFilter} className='btn btn-dark' type='button'>Reset</button>
        </div>
        <div className="customerCards">
          {toRender.slice(0, 6).map((x) => QuoteCard(x))}
          <div className='cta'>
            <div className="ctaIcon">
              <img src={logo} alt=""/>
              <h3>See how LogRocket can help you stop guessing about your digital experiences</h3>
            </div>
            <Link className='btn btn-secondary justify-content-center' to='/request-demo?customers'>
              Request a Demo
            </Link>
          </div>
          {toRender.slice(6).map((x) => QuoteCard(x))}
        </div>
      </div>
    </div>
  );
};

export default SanityCustomerListBlock

export const query = graphql`
fragment SanityCustomerList on SanityCustomerList {
  id,
  quotes {
    ...SanityQuotes,
  }
}
`
