import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from '@emotion/styled';
import SanityPortableText from '../shared/SanityPortableText';
import sideImageRight from '../../assets/svgs/thin-dots-right.svg';
import sideImageLeft from '../../assets/svgs/thin-dots-left.svg';
import { breakpoints } from '../../styles/pages/breakpoints';

const Section = styled.section`
position:relative;
&::before{
  content:'';
  background-image: url(${sideImageLeft});
  position: absolute;
  left: 0%;
  top: 29%;
  width: 100%;
  z-index: 0;
  height: 50%;
  background-repeat: no-repeat;
}
.first-dots{
  content:'';
  background-image: url(${sideImageRight});
  position: absolute;
  right: -2%;
  top: 8%;
  width: 10%;
  z-index: 0;
  height: 23%;
  background-repeat: no-repeat;
}
.seconds-dots{
    content:'';
    background-image: url(${sideImageRight});
    position: absolute;
    right: 0%;
    top: 59%;
    width: 8%;
    z-index: 0;
    height: 28%;
    background-repeat: no-repeat;
  }

@media (max-width: ${breakpoints.lg}) {


}
@media (max-width: ${breakpoints.md}) {

    .first-dots{
      top:3%;
    }
  }
@media (max-width: ${breakpoints.sm}) {
  &::before{
    display: none;
  }
  .first-dots,.seconds-dots{
    display: none;
  }
}
`

const BgCard = ({ image, cardTitle, text, action, internalName }: Queries.SanityCardData) => {
    const cardContent = (!(internalName === 'Issue Management and Error Tracking')) ? (<div className='card-block d-flex flex-md-row flex-column flex-md-nowrap justify-content-center align-items-center'>
        <div className=' d-block d-md-none my-auto px-auto'>
            <GatsbyImage
                image={getImage(image?.asset?.gatsbyImageData)}
                alt=''
                style={{ objectFit: 'contain' }} />
        </div>
        <div className='col-md-4' style={{ justifyContent: 'center', alignItems: 'center', margin: 'auto', color: 'black' }}>
            {/* <h4>{cardTitle}</h4> */}
            <div className='cards-title'>
            <SanityPortableText {...cardTitle} />
            </div>
            <div className='card-text'>
            {text?.map(({ _id: id, ...texts }) => <div key={id}> <SanityPortableText _id={id} {...texts} /></div>)}
            </div>
        </div>
        <div className='col-md-8 d-none d-md-block my-auto'><GatsbyImage
            image={getImage(image?.asset?.gatsbyImageData)}
            alt=''
            style={{ objectFit: 'contain' }} />
        </div>
    </div>) : (
        <div className='card-block d-flex flex-column justify-content-center m-auto'>
            <GatsbyImage
                image={getImage(image?.asset?.gatsbyImageData)}
                className='mb-5 mb-md-0' alt=''
                style={{ objectFit: 'contain' }} />
            <div className=' ml-md-3' style={{ color: 'black' }}>
                <div className='cards-title'>
                <SanityPortableText {...cardTitle} />
                </div>
                <div className='card-text'>
                {text?.map(({ _id: id, ...texts }) => <div key={id}> <SanityPortableText _id={id} {...texts} /></div>)}
                </div>
            </div>
        </div>
    )
    return (<>
        <div className='d-none d-md-flex flex-column card col-md-7  px-5 py-5 justify-content-center' style={{ border: '0.75px solid #E5E5E5', boxShadow: '2.01612px 2.01612px 10.0806px rgba(0, 0, 0, 0.0196078)', borderRadius: '0', zIndex: 1 }}>
            {cardContent}
            {action?.url?.current && <Link to={action?.url?.current} className='anchor-link' >
                <div className='long-arrow-right' />
            </Link>}
        </div>
        <Link className='d-block d-md-none card col-md-7 px-5 py-4 text-decoration-none' to={action?.url?.current}>
            {cardContent}
        </Link>
    </>)
}


const SmCard = ({ image, cardTitle, text, action }: Queries.SanityCardData) => {
    const cardContent = (
        <div className='card-block d-flex flex-column  justify-content-center m-auto'>
            <GatsbyImage
                image={getImage(image?.asset?.gatsbyImageData)}
                className='mb-5' alt=''
                style={{ objectFit: 'contain' }} />
            <div className='ml-md-3'>
                <div className='cards-title'>
                <SanityPortableText {...cardTitle} />
                </div>
                <div className='card-text'>
                {text?.map(({ _id: id, ...texts }) => <div key={id}> <SanityPortableText _id={id} {...texts} /></div>)}
                </div>
            </div>
        </div>)
    return (
        <>
            {action?.url?.current && <Link className='d-block d-md-none card col-md-4 px-5 py-4 justify-content-center align-items-center' to={action?.url?.current}>
                {cardContent}
            </Link>}
            <div className='d-none d-md-block card col-md-4 px-5 py-5' style={{ border: '0.75px solid #E5E5E5', boxShadow: '2.00492px 2.00492px 10.0246px rgba(0, 0, 0, 0.0196078)', borderRadius: '0', zIndex: 1 }}>
                {cardContent}
                {action?.url?.current && <Link to={action?.url?.current} className='anchor-link'>
                    <div className='long-arrow-right' />
                </Link>}
            </div>
        </>
    )
}

const SanityCardsWithDifferentSize = ({ cardData, backgroundColor, textBlock }: Queries.SanityCardsWithDifferentSize) => {
    let evenSize = false;
    let oddSize = true;
    return (
        <Section className='session_card' style={{ backgroundColor: `${backgroundColor?.hex}`, opacity: backgroundColor?.alpha }}>
            <div className='cards-title' style={{ zIndex: 1 }}>{textBlock.map(({ _id: id, ...texts }) =>
                <SanityPortableText key={id} _id={id} {...texts} />)}</div>
            <div className='row justify-content-center cards-with-different-size'>
                {cardData.map((data, index) => {
                    if (index % 2 === 0) {
                        const CardBox = evenSize ? <BgCard {...data} key={data.id} /> : <SmCard key={data.id} {...data} />
                        evenSize = !evenSize
                        return CardBox
                    }
                    const CardBox = oddSize ? <BgCard {...data} key={data.id} /> : <SmCard key={data.id} {...data} />
                    oddSize = !oddSize
                    return CardBox

                })}
            </div>
            <div className='first-dots' />
            <div className='seconds-dots' />
        </Section>
    );
};

export default SanityCardsWithDifferentSize

export const query = graphql`
fragment SanityCardsWithDifferentSize on SanityCardsWithDifferentSize {
    id
    backgroundColor {
      hex
      alpha
    }
    cardData {
      ...SanityCardDataFragment
    }
    textBlock {
      ...SanityTextBlock
    }
}
`
