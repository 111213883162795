import React from 'react';
import { getSrc } from 'gatsby-plugin-image'
import { graphql, PageProps } from 'gatsby';
import { GenerateBlock } from '../components/blocks';
import SEO from '../components/seo';
import Layout from '../components/layout';

export default function PageTemplate({data}: PageProps<Queries.PageLayoutQuery>) {
  const { seo, components, ...rest } = data.sanityPage;
  const { title: seoTitle, rawDescription: seoDescription, openGraphImage } = seo || {}
  return (
    <Layout>
      <SEO
        image={openGraphImage && getSrc(openGraphImage.asset.gatsbyImageData)}
        title={seoTitle}
        description={seoDescription} />
      <div style={{backgroundColor: rest.pageBackgroundColor?.hex, opacity: rest.pageBackgroundColor?.alpha}}>
        {components?.map((block) => (
          <GenerateBlock key={block?.entityId || block} block={block} {...rest} />
        ))}
      </div>
    </Layout>
  );
}

export const query = graphql`
query PageLayout($id: String) {
  sanityPage(id: {eq: $id}) {
    slug {
      current
    }
    pageBackgroundColor {
      hex
      alpha
    }
    seo {
      keywords
      title
      rawDescription:  _rawDescription
      openGraphImage {
        asset {
          gatsbyImageData
        }
      }
    }
    components {
      typename: __typename
      ... on SanityDocument {
				entityId: _id
      }
      ...SanityActionComponent
      ...SanityCardsCarousel
      ...SanityCardsData
      ...SanityCarousel
      ...SanityAlternativeHeroComponent
      ...SanityQuoteHeroComponent
      ...SanityHeroComponent
      ...SanityIntegration
      ...SanityQuotes
      ...SanityWorkStack
      ...SanityTextBlock
      ...SanityCardsWithDifferentSize
      ...SanityWebinars
      ...SanityAccordionMenu
      ...SanityListing
      ...SanityCustomerList
    }
  }
}
`
