import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { GatsbyImage } from 'gatsby-plugin-image';
import { breakpoints } from '../../styles/pages/breakpoints';
import Section from '../section';
import SanityPortableText from '../shared/SanityPortableText';
import SanityWithInAction from '../shared/SanityWithInAction';

const StyledWebinars = styled.div`
  .h2 {
    text-align: center;
    font-weight: 400;
    margin-bottom: var(--small);
  }

  .webinars {
    & > * {
      &:not(:last-child) {
        margin-bottom: var(--medium);

        @media (max-width: ${breakpoints.xs}) {
          margin-bottom: var(--x-small);
        }
      }
    }
  }
`;
const StyledWebinarCard = styled.div`
  display: flex;
  justify-content: space-between;
  padding: var(--medium);
  background: var(--white);
  box-shadow: var(--shadow-light);
  filter : drop-shadow(rgba(0, 0, 0, 0.09) 0mm 3mm 2mm);

  & > *:not(:last-child) {
    margin-right: var(--xx-small);
  }

  @media (max-width: 1050px) {
    flex-direction: column;
    max-width: 600px;
    margin: auto;

    & > *:not(:last-child) {
      margin-right: 0;
      margin-bottom: var(--x-small);
    }
  }

  @media (max-width: ${breakpoints.xs}) {
    padding: var(--small);

    & > *:not(:last-child) {
      margin-bottom: var(--xx-small);
    }
  }

  @media (max-width: ${breakpoints["2xs"]}) {
    padding: var(--xx-small);
  }

  p {
    margin: 0;
  }

  .intro {
    flex: 0 0 35%;
  }

  .subtitle {
    color: var(--purple);
    text-transform: uppercase;
    font-weight: 500;
  }

  .h3 {
    margin: var(--micro) 0;
    font-weight: 400;
  }

  .title-secondary {
    margin-bottom: var(--small);
    font-weight: 600;
    font-size: 20.8215px;
    line-height: 25px;
    color: var(--purple);
  }

  .speaker {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: var(--small);
    }

    @media (max-width: 1050px) {
      &:not(:last-child) {
        margin-bottom: var(--micro);
      }
    }

    &-img {
      margin-right: var(--milli);

    }

    &-title {
      margin-bottom: var(--nano);
      font-weight: 600;
      font-size: 19px;
      line-height: 27px;
    }

    &-subtitle {
      color: var(--gray);
      font-size: 19px;
      line-height: 27px;
    }
  }

  .time {
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
  }

  .time-container {
    @media (max-width: 1050px) {
      display: flex;
      align-items: center;
    }

    .title-secondary {
      margin-bottom: 0;
      margin-right: var(--small);
      color:#838383
    }

  }
`;

const WebinarCard = ({ text, speakers, time, actionComponent, companyImage }: Queries.SanityWebinarCard) => <StyledWebinarCard>
  <div className='intro'>
    <GatsbyImage image={companyImage?.image?.asset?.gatsbyImageData} alt='' className='mb-4' style={{ objectFit: 'contain' }} />
    {text.map(({ _id: id, ...texts }) => (
      <SanityPortableText _id={id} {...texts} key={id} />
    ))}
  </div>
  <div>
    <h4 className='title-secondary'>Speakers</h4>
    {speakers.map((speaker) => (
      <div className='speaker' key={speaker.id}>
        <GatsbyImage image={speaker?.userImage?.asset?.gatsbyImageData} className='rounded-circle speaker-img' alt='' style={{ objectFit: 'contain' }} />
        <div>
          <h5 className='speaker-title'>{speaker.name}</h5>
          <p className='speaker-subtitle'>{speaker.designation}</p>
        </div>
      </div>
    ))}
  </div>

  <div className='d-flex flex-column justify-content-center'>
    <SanityWithInAction  {...actionComponent} />

    <div className='time-container mt-3'>
      <h5 className='title-secondary mb-1'>Time</h5>
      <p className='time'>{time} MINS</p>
    </div>
  </div>
</StyledWebinarCard>

const SanityWabinarComponent = ({ title, webinarCard }: Queries.SanityWebinars) => <Section>
  <StyledWebinars className='wrapper'>
    <h2 className='h2'>{title}</h2>
    <div className='webinars'>
      {webinarCard.map((webinar) => (
        <WebinarCard key={webinar.id} {...webinar} />
      ))}
    </div>
  </StyledWebinars>
</Section>

export default SanityWabinarComponent;

export const query = graphql`
fragment SanityWebinars on SanityWebinars {
    id
    title
    webinarCard {
      id
      text {
        ...SanityTextBlock
      }
      time
      actionComponent {
        ...SanityActionComponent
      }
      speakers {
        ...SanityQuotes
      }
      companyImage {
        image {
          asset {
            gatsbyImageData
          }
        }
      }
    }
  }
`
