import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PortableText from '@sanity/block-content-to-react';
import styled from '@emotion/styled';
import sideImageRight from '../../assets/svgs/thin-dots-right.svg';
import sideImageLeft from '../../assets/svgs/thin-dots-left.svg';
import SanityWithInAction from '../shared/SanityWithInAction';
import SanityPortableText from '../shared/SanityPortableText';
import { breakpoints } from '../../styles/pages/breakpoints';

const SanityQuotesBlock = ({ companyImage, userImage, _rawFeedback: rawFeedback, CtaText, designation, name, team, backgroundColor, actionComponent, isReversed, dots }: Queries.SanityQuotes) => {

  const Section = styled.section`
  position:relative;
  over-flow: hidden;
  padding: ${dots === 'bothSides' ? '3rem 0' : '6rem  0'};
    &::before{
      display: ${dots === 'bothSides' || dots === 'left'  ? 'block' : 'none'};
      content:'';
      background-image: url(${sideImageLeft});
      position: absolute;
      left: 0%;
      top: 0%;
      width: 5%;
      z-index: 0;
      height: 100%;
      background-repeat: no-repeat;
    }
    &::after{
      content:'';
      display: ${(dots === 'right' || dots === 'bothSides' ) ? 'block' : 'none'};
      background-image: url(${sideImageRight});
      position: absolute;
      right: 0%;
      top: 0%;
      width: 5%;
      z-index: 0;
      height: 100%;
      background-repeat: no-repeat;
    }
    .quote-feedback{
      max-width:82%;
      font-size:18px;
      color: #353637;
    }
    .rightSide {
      padding-left:5rem;
      padding-right:2rem
    }
    @media (max-width: ${breakpoints.lg}) {
      &::before{
        width: 7%;
        height: 100%;
      }
      &::after{
        width: 7%;
        height: 100%;
      }
    }
    @media (max-width: ${breakpoints.md}) {
      &::before{
        width: 8%;
        height: 100%;
      }
      &::after{
        width: 8%;
        height: 100%;
      }
      .rightSide {
        padding-left:3rem;
        padding-right:2rem
      }
    }
    @media (max-width: ${breakpoints.sm}) {
      padding: ${dots === 'bothSides' ? '3rem 0' : '3rem 0'};
      .btn {
        height:42px;
        min-width:155px;
      }
      &::before{
        display: none;
      }
      &::after{
        display: none;
      }
      .quote-feedback{
        max-width:100%
      }
      .rightSide {
        padding-left:1.5rem;
        padding-right:1.5rem
      }
    }
    @media (min-width: 1600px) {
      max-width: 1580px !important;
        margin: 0 auto;
    }
`
  return(
  <Section className='user-feedback d-flex my-2'>
    <div className='container d-flex justify-content-center my-auto'>
      <div className={`d-flex flex-column-reverse ${isReversed ? 'flex-md-row-reverse' : 'flex-md-row'} row-container justify-content-center `}>
        {/* {actionComponent && <div className='d-flex d-md-none mt-5 justify-content-center'>
          <SanityWithInAction {...actionComponent} />
        </div>} */}
        <div className='col-xs-12 col-md-6 left px-3 px-md-auto py-5 mx-auto' style={{ backgroundColor: backgroundColor?.hex, opacity: backgroundColor?.alpha, filter :'drop-shadow(rgba(0, 0, 0, 0.09) 0mm 3mm 2mm)' }}>
          <div className='d-flex flex-column justify-content-center'>
            <GatsbyImage image={getImage(userImage?.asset?.gatsbyImageData)} alt={name} className='mx-auto' />
          </div>
          <div className='feedbacker text-center'>
            <div className='row mt-3'>
              <div className='col d-flex flex-column justify-content-center'>
                <h4 className='name m-0'>{name}</h4>
                <p className=' mt-2 mb-3' style={{fontSize:'13px', color:'#838383'}}>{designation}</p>
              </div>
            </div>
            <div className='col mx-auto quote-feedback'>
            <PortableText blocks={rawFeedback}  />
            </div>
          </div>
        </div>
        <div className='col-xs-12 col-md-6 rightSide  d-flex flex-column align-items-start align-items-md-start justify-content-center'>
          <div className='mb-4 mb-md-5 company-image'>
            <GatsbyImage image={getImage(companyImage?.asset?.gatsbyImageData)} alt={team || 'companyImage'} style={{ maxWidth: '230px' }} />
          </div>
          <div className='cta-text mb-3 mb-md-5'>
            {CtaText.map(({ _id: id, ...texts }) => (
              <div className='mb-4' key={id}>
                <SanityPortableText _id={id} {...texts} key={id} />
              </div>
            ))}
          </div>
          <div className='d-block d-md-block mb-5 mb-md-0'>
            {actionComponent && <SanityWithInAction {...actionComponent} />}
          </div>
        </div>
      </div>
    </div>
  </Section>
)
            }

export default SanityQuotesBlock;

export const query = graphql`
fragment SanityQuotes on SanityQuotes {
  id
  companyImage {
    asset {
      gatsbyImageData(placeholder: NONE)
    }
  }
  userImage {
    asset {
      gatsbyImageData(placeholder: NONE)
    }
  }
  listingImage {
    asset {
      gatsbyImageData(placeholder: NONE)
    }
  }
  listingTitle
  _rawFeedback
  CtaText {
    ...SanityTextBlock
  }
  designation
  name
  team
  backgroundColor {
    hex
    alpha
  }
  actionComponent {
    ...SanityActionComponent
  }
  isReversed
  dots
  tags {
    title
    _id
    filterType
  }
}
`
