import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import SanityPortableText from '../shared/SanityPortableText';
import SanityWithInAction from '../shared/SanityWithInAction';
import backgroundCircles from '../../assets/svgs/background-circles.svg';

const COLUMNS = {
  1: 'col-md-12',
  2: 'col-md-6',
  3: 'col-md-3 mx-3 mx-md-5 my-3',
  4: 'col-md-3',
};

function sideBySideCardClass(cardsColumns: number) {
  return cardsColumns === 2 ? 'header-wrapper' : '';
}

const SanityCardsDataBlock = ({ cardsTitle, cardData, cardsColumns, backgroundColor, verticalBorder }: Queries.SanityCardsData) => (
  <div className={`${cardsColumns === 2 && backgroundColor && 'background-wide-screen '}`} style={{backgroundColor: backgroundColor?.hex, opacity: backgroundColor?.alpha}}>
  <div style={{ backgroundColor: backgroundColor?.hex, opacity: backgroundColor?.alpha, backgroundImage: cardsColumns === 2 && backgroundColor && `url(${backgroundCircles})`, backgroundRepeat: 'no-repeat' }} className={`${(cardData.length > 1 && cardsColumns === 1 && cardsTitle.length > 0) && 'card-container__with_title'} ${'card-container'} ${(cardData.length > 1 && cardsColumns === 1 && !cardsTitle.length) && 'card-container__less_padding'} ${( cardData.length >= 1 && cardsColumns === 2) && 'card-container-with-two-cards'} ${backgroundColor && cardData.length === 1 && cardsColumns !== 2 && 'single-card-container'} ${(cardData.length > 1 && cardsColumns >= 3) && 'card-column-container'} ${cardsColumns === 2 && backgroundColor && 'background-circles'}`}>
    {
      cardsTitle.length > 0 &&
      <div className='pb-5 px-1 cards-title'>
        {cardsTitle.map(({ _id: id, ...texts }) => (
          <SanityPortableText _id={id} key={id} {...texts} />
        ))}
      </div>
    }
    <div className={`${cardsColumns !== 3 ? sideBySideCardClass(cardsColumns) : 'mx-0 mx-md-3' }`}>
      <div className="row m-0 p-0 justify-content-center">
        {cardData.map((data, index, cards) => (
          <div className={`${( cardData.length >= 1 && cardsColumns === 2) ? '' : 'card-overflow'} ${COLUMNS[cardsColumns]} p-0 info-card  ${verticalBorder && index < cardData?.length - 1 && backgroundColor && 'vertical-dots'} ${verticalBorder && index < cardData?.length - 1 && !backgroundColor && 'vertical-dots-secondary'} ${ !data.isReversed && data.image ? 'card-overflow-right' : 'card-overflow-left'}`}
            style={{ backgroundColor: !data.image && `${data.backgroundColor?.hex}`, opacity: backgroundColor?.alpha, filter: cardData.length > 1 && cardsColumns >= 3 && 'drop-shadow(rgba(0, 0, 0, 0.09) 0mm 3mm 2mm)' }} key={data?.id}>
            <div className={`row  ${ !data.isReversed && data.image ? 'flex-row-reverse justify-content-end' : 'flex-row-in justify-content-start'} ${(data?.image && (index !== cards.length - 1)) && cardsColumns === 1 && !data?.backgroundColor && 'img_parent_wo_bc'}  justify-content-md-start align-items-center ${cardsColumns === 2 && backgroundColor && 'justify-content-center'} ${cardsColumns === 2 && !backgroundColor && ' px-0 mx-0 px-md-4 mx-md-auto'}`} >
              <div className={` d-block d-md-none ${data.image && `col-12 col-md-5 px-0 ${cards.length > 1 && cardsColumns === 1 && data?.backgroundColor  && 'px-md-0'} ${ cards.length <= 1 && cardsColumns !== 1 && 'px-lg-5'} ${cards.length > 1 && cardsColumns === 1 && !data?.backgroundColor && !data.isReversed  && 'card-padding-left'} ${cards.length > 1 && cardsColumns === 1 && !data?.backgroundColor && data.isReversed  && 'card-padding-right'}   py-3 pt-md-0`} d-flex justify-content-start
               align-items-center mx-md-auto ${cardsColumns === 3 && 'w-75 mt-5 mx-2'}`}>
            <div className={`d-flex flex-column justify-content-start   ${cardsColumns === 2 && backgroundColor ? 'align-items-center mx-5 mx-md-0' : 'align-items-start'} ${backgroundColor && cardData.length === 1 && 'card-text'}`}>
                  {
                    data.icon &&
                    <div className={` ${!data.image && cardsColumns === 2 && 'mx-auto'} mb-3`}>
                      <GatsbyImage image={data?.icon?.asset?.gatsbyImageData} alt='icon' className='img-fluid' />
                    </div>
                  }
                  {data?.anchor ? (
                    <Link  to={`/features#${data?.anchor}`} title='Our team'>
                      {/* <p id={data?.anchor} className={`${backgroundColor && cardData.length === 1 ? 'sigle-card-title' : 'info-title heavy-font'}`} style={{ color: data.titleColor?.hex, opacity: data.titleColor?.alpha }}>
                      {data?.cardTitle}
                    </p> */}
                      <div id={data?.anchor} className={`${backgroundColor && cardData.length === 1 ? 'sigle-card-title' : 'info-title heavy-font'}`}>
                        <SanityPortableText {...data?.cardTitle} />
                      </div>
                    </Link>
                  ) : (
                    <div id={data?.anchor} className={`${backgroundColor && cardData.length === 1 ? 'sigle-card-title' : 'info-title heavy-font'}`}>
                      <SanityPortableText {...data?.cardTitle} />
                    </div>
                  )}
                  </div>
                  </div>

              {
                data.image && <div className={` col-12 col-md-7 d-flex justify-content-center align-items-center ${(data.backgroundColor && data?.image) && 'img_parent_right'} ${(cardData.length > 1 && cardsColumns === 1) && data?.backgroundColor && 'px-5'} ${(cardData.length > 1 && cardsColumns === 1) && !data?.backgroundColor && 'pl-0'}`} style={{ backgroundColor: `${data.backgroundColor?.hex}`, opacity: backgroundColor?.alpha }}>
                  <GatsbyImage image={data.image?.asset?.gatsbyImageData} alt={`${data.cardTitle}`} className='w-100' style={{ objectFit: 'contain'}} />
                </div>
              }
              <div className={`${data.image && `col-12 col-md-5 px-0 ${cards.length > 1 && cardsColumns === 1 && data?.backgroundColor  && data.isReversed && 'px-md-5'} ${ cards.length <= 1 && cardsColumns !== 1 && 'px-lg-5'} ${cards.length > 1 && cardsColumns === 1 && !data?.backgroundColor && !data.isReversed  && 'card-padding-left'} ${cards.length > 1 && cardsColumns === 1 && !data?.backgroundColor && data.isReversed  && 'card-padding-right'}  pt-md-0`} d-flex ${cardsColumns === 1 && cards.length === 1 && backgroundColor ? 'justify-content-start' : 'justify-content-start'} align-items-center mx-auto ${cardsColumns === 3 && 'w-75 my-5 mx-2'}`}>
                <div className={`d-flex flex-column justify-content-center   ${cardsColumns === 2 && backgroundColor ? 'align-items-center mx-5 mx-md-0' : 'align-items-start'} ${backgroundColor && cardData.length === 1 && 'card-text'}`}>
                  {
                    data.icon &&
                    <div className={` d-none d-md-block ${!data.image && cardsColumns === 2 && 'mx-auto'} mb-4`}>
                      <GatsbyImage image={data?.icon?.asset?.gatsbyImageData} alt='icon' className='img-fluid' />
                    </div>
                  }
                  {data?.anchor ? (
                    <Link className='d-none d-md-block' to={`/features#${data?.anchor}`} title='Our team'>
                      {/* <p id={data?.anchor} className={`${backgroundColor && cardData.length === 1 ? 'sigle-card-title' : 'info-title heavy-font'}`} style={{ color: data.titleColor?.hex, opacity: data.titleColor?.alpha }}>
                      {data?.cardTitle}
                    </p> */}
                      <div id={data?.anchor} className={`${backgroundColor && cardData.length === 1 ? 'sigle-card-title' : 'info-title heavy-font'}`}>
                        <SanityPortableText {...data?.cardTitle} />
                      </div>
                    </Link>
                  ) : (
                    <div id={data?.anchor} className={`d-none d-md-block ${backgroundColor && cardData.length === 1 ? 'sigle-card-title' : 'info-title heavy-font'}`}>
                      <SanityPortableText {...data?.cardTitle} />
                    </div>
                  )}
                  <div className="w-full pt-3">
                    {data.text.map(({ _id: id, ...texts }) => (

                      <div key={id} className={`text ${cardsColumns === 2 && 'text_max_width'}`}>

                        <SanityPortableText _id={id} {...texts} />
                      </div>
                    ))}
                    {data.action && <div className={`mt-5 ${!data.image && 'mx-auto'} mb-5 mb-md-0`}>
                      <SanityWithInAction {...data.action} />
                    </div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div></div>
    </div>);
export default SanityCardsDataBlock;

export const query = graphql`
fragment SanityCardDataFragment on SanityCardData {
  id
  icon {
    asset {
      gatsbyImageData(placeholder: NONE)
    }
  }
  image {
    asset {
      gatsbyImageData(placeholder: NONE)
    }
  }
  isReversed
  backgroundColor {
    hex
    alpha
  }
  action {
    ...SanityActionComponent
  }
  anchor
  text {
    ...SanityTextBlock
  }
  cardTitle {
    ...SanityTextBlock
  }
  internalName
}

fragment SanityCardsData on SanityCardsData {
  id
  cardsTitle {
    ...SanityTextBlock
  }
  cardsColumns
  borderBottom
  backgroundColor {
    hex
    alpha
  }
  cardData {
    ...SanityCardDataFragment
  }
  verticalBorder
}
`
