import * as React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import SanityPortableText from '../shared/SanityPortableText';
import sideImageRight from '../../assets/svgs/thin-dots-right.svg';
import sideImageLeft from '../../assets/svgs/thin-dots-left.svg';
import { breakpoints } from '../../styles/pages/breakpoints';

const Section = styled.section`
@media (min-width: 1600px) {
  max-width: 1580px !important;
    margin: 0 auto;

}
position:relative;
over-flow: hidden;
&::before{
  content:'';
  background-image: url(${sideImageLeft});
  position: absolute;
  left: -2%;
  top: 45%;
  width: 17%;
  z-index: 0;
  height: 43%;
  background-repeat: no-repeat;
}
&::after{
  content:'';
  background-image: url(${sideImageRight});
  position: absolute;
  right: -2%;
  top: 45%;
  width: 7%;
  z-index: 0;
  height: 43%;
  background-repeat: no-repeat;
}
@media (max-width: ${breakpoints.lg}) {
  &::before{
    width: 10%;
    height: 40%;
  }
  &::after{
    width: 10%;
    height: 40%;
  }
}
@media (max-width: ${breakpoints.md}) {
  &::before{
    width: 10%;
    height: 40%;
  }
  &::after{
    width: 10%;
    height: 40%;
  }
}
@media (max-width: ${breakpoints.sm}) {
  &::before{
    display: none;
  }
  &::after{
    display: none;
  }
}
`

const SanityWorkStackBlock = ({ text, sdkCard }: Queries.SanityWorkStack) => (
  <Section className='work-stack-container'>
    <div className='header-wrapper d-flex flex-column justify-content-center' >
      {text && text.map(({ _id: id, ...body }) => <SanityPortableText _id={id} {...body} key={id} />)}
    </div>

    <div className='row justify-content-center align-items-start mt-md-5' style={{ zIndex: 1 }}>
      {sdkCard.map(card => (
        <div key={card.id} className='col-md-5 d-flex flex-column justify-content-center align-items-center'>
          <div className='mx-auto my-5 col-11 d-flex justify-content-center'>
            <GatsbyImage image={card?.mainImage?.asset?.gatsbyImageData} alt='work-stack' className='work-stack' style={{ objectFit: 'contain', filter: 'drop-shadow(rgba(0, 0, 0, 0.1) 0mm 5mm 3mm)' }} />
          </div>
          <div className='col-11 card d-flex flex-row flex-wrap justify-content-around tech-icons mt-md-5' style={{ backgroundColor: 'transparent', border: 'none' }}>
            {card?.icons.map((item) => (
              <div
                className='col-4 col-md d-flex flex-column justify-content-between align-items-center icon-container mt-2 mr-2'
                key={item.id}>
                <GatsbyImage image={item?.image?.asset?.gatsbyImageData} alt='tech icon' />
                <h6>{item?.name}</h6>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  </Section>
)
export default SanityWorkStackBlock

export const query = graphql`
fragment SanityArrayOfImages on SanityArrayOfImages {
  id
  mainImage {
    asset {
      gatsbyImageData(placeholder: NONE)
      title
    }
  }
  cardTitle
  icons {
    id
    image {
      asset {
        gatsbyImageData(width: 49, placeholder: NONE)
      }
    }
    alt
    name
  }
  _id
}

fragment SanityWorkStack on SanityWorkStack {
  id
  text {
    ...SanityTextBlock
  }
  sdkCard {
    ...SanityArrayOfImages
  }
}
`
