import * as React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, Link } from 'gatsby';

const listingItem = ({title, text, actionComponent: { url, icon, title: linkTitle }}: Queries.SanityListing['listingItems'][0]) => (
  <div className="list-item">
    <h2 className="title">
      {title}
    </h2>
    <div className="body">
      {text}
      <Link to={url?.current}>
        {linkTitle}
        <GatsbyImage image={icon?.asset?.gatsbyImageData} alt='icon' className='image-icon' style={{objectFit:'contain'}} />
      </Link>
    </div>
  </div>
);

const SanityListing = ({ title, listingItems }: Queries.SanityListing) => (
  <div className="container content-area">
    <div className="list-wrapper">
      <h1>{title}</h1>
      <span className="list">
        {listingItems.map(x => listingItem(x))}
      </span>
    </div>
  </div>
)
export default SanityListing

export const query = graphql`
fragment SanityListing on SanityListing {
  title,
  listingItems {
    title,
    text,
    actionComponent {
      ...SanityActionComponent
    }
  }
}
`
