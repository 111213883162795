import React from 'react';
import PropTypes from 'prop-types';

export const GenerateBlock = ({
  block,
  ...restProps
}) => {
  const { typename, ...rest } = block;
  // eslint-disable-next-line @typescript-eslint/no-var-requires ,global-require, import/no-dynamic-require
  const req = require(`./${typename}.tsx`);
  const BlockComponent = req.default;
  return BlockComponent ? (
    <div className={`${typename} `}>
      <BlockComponent {...rest} {...restProps} />
    </div>
  ) : (
    "TODO"
  );
};

GenerateBlock.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  block: PropTypes.object,
  index: PropTypes.number
};
