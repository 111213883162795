import * as React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const alignmentAction = {
  'left': 'justify-content-start',
  'center': 'justify-content-center',
  'right': 'justify-content-end',
}

const SanityActionBlock = ({ icon, title, mobileTitle, url, variant, alignment }: Queries.SanityActionComponent) => (
  <>
    {icon ? <div>
      {(url?.current.includes('http') || url == null) ? <a href={url?.current} className='icon'>
        <GatsbyImage image={icon?.asset?.gatsbyImageData} alt='icon' className='img-fluid' style={{ objectFit: 'contain' }} />
      </a> :
        <Link className='icon' to={url?.current}>
          <GatsbyImage image={icon?.asset?.gatsbyImageData} alt='icon' className='img-fluid' style={{ objectFit: 'contain' }} />
        </Link>}
    </div>
      :
      <div className={`d-flex ${alignmentAction[alignment || 'left']} align-items-center`} >
        <div className='d-md-block d-none'>
          <div style={{ alignItems: 'center', padding: '70px 0 70px 0' }}>
            {
              (url?.current.includes('http') || url == null) ?
                <a
                  href={url?.current}
                  className={(variant === 'underline' || variant === 'plain') ? `${variant}` : `btn btn-${variant}`}
                >
                  {(variant === 'underline' || variant === 'plain') ? <p> {title || mobileTitle}</p> : (title || mobileTitle)}
                  <div className="long-arrow-right" />
                </a> :
                <Link to={url?.current} className={(variant === 'underline' || variant === 'plain') ? `${variant}` : `btn btn-${variant}`}>
                  {(variant === 'underline' || variant === 'plain') ? <p> {title || mobileTitle}</p> : (title || mobileTitle)}
                  <div className="long-arrow-right" />

                </Link>
            }
          </div>
        </div>

        <div className='d-md-none d-block'>
          <div style={{ alignItems: 'center', padding: '40px 0 40px 0' }}>
            {(url?.current.includes('http') || url == null) ? <a
              href={url?.current}
              className={(variant === 'underline' || variant === 'plain') ? `${variant}` : `btn btn-${variant}`}
            >
              {(variant === 'underline' || variant === 'plain') ? <p> {title || mobileTitle}</p> : (title || mobileTitle)}
              <div className="long-arrow-right" />

            </a> :
              <Link to={url?.current} className={(variant === 'underline' || variant === 'plain') ? `${variant}` : `btn btn-${variant}`}>
                {(variant === 'underline' || variant === 'plain') ? <p> {title || mobileTitle}</p> : (title || mobileTitle)}
                <div className="long-arrow-right" /> </Link>
            }
          </div>
        </div>
      </div>
    }
  </>
)
export default SanityActionBlock

export const query = graphql`
fragment SanityActionComponent on SanityActionComponent {
  id
  icon {
    asset {
      gatsbyImageData(placeholder: NONE)
    }
  }
  mobileTitle
  title
  url {
    current
  }
  variant
  alignment
}
`
