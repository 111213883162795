import * as React from 'react';
import PortableText from '@sanity/block-content-to-react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { inlineSerializer } from '../utility/SanityHelper';

const TextBLock =  styled.div`
ul {
  list-style:none;
  padding-inline-start: 0px
}
li {
  &:before{
    content: "-";
    margin-right: 0.2rem
  }
}`

const TextAlignment = {
  'left': 'text-left',
  'center': 'text-center',
  'right': 'text-right',
}

const SanityTextBlock = ({ _rawDescription: rawDescription, textColor, textAlignment }: Queries.SanityTextBlockFragment) => (
  <TextBLock className={`text-block container ${TextAlignment[textAlignment || 'left']} my-5 py-4`} style={{ color: textColor?.hex, opacity: textColor?.alpha }}>
    <div>
      <PortableText blocks={rawDescription} serializers={inlineSerializer('100', textColor?.hex)} />
    </div>
  </TextBLock>
);
export default SanityTextBlock

export const query = graphql`
fragment SanityTextBlock on SanityTextBlock {
  _id
  _rawDescription
  textColor {
    hex
    alpha
  }
  backgroundColor {
    hex
    alpha
  }
  textAlignment
  title
}
`
