import * as React from 'react';
import PortableText from '@sanity/block-content-to-react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styled from '@emotion/styled';
import SanityWithInAction from '../shared/SanityWithInAction';
import SanityPortableText from '../shared/SanityPortableText';
import sideImageRight from '../../assets/svgs/thin-dots-right.svg';
import sideImageLeft from '../../assets/svgs/thin-dots-left.svg';
import { breakpoints } from '../../styles/pages/breakpoints';

const Section = styled.section`
position:relative;
&::before{
  content:'';
  background-image: url(${sideImageLeft});
  position: absolute;
  left: -2%;
  top: 23%;
  width: 7%;
  z-index: 0;
  height: 100%;
  background-repeat: no-repeat;
}
&::after{
  content:'';
  background-image: url(${sideImageRight});
  position: absolute;
  right: -2%;
  top: 23%;
  width: 6%;
  z-index: 0;
  height: 100%;
  background-repeat: no-repeat;
}
@media (max-width: ${breakpoints.lg}) {
  &::before{
    width: 10%;
    height: 100%;
    left: -4%;
  }
  &::after{
    width: 5%;
    height: 100%;
    right:0%
  }
}
@media (max-width: ${breakpoints.md}) {
  &::before{
    display: none;
  }
  &::after{
    display: none;
  }
}
@media (min-width: 1600px) {
  max-width: 1580px !important;
    margin: 0 auto;

}
`

interface NextArrowProp {
  className?: string,
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}
interface PrevArrowProp {
  className?: string,
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}
function NextArrow({ className, onClick }: NextArrowProp) {

  return <button type='button' onClick={onClick} className={className} style={{ zIndex: '999', overflow: 'hidden' }}> <i className='arrow right-arrow' /></button>
}
function PrevArrow({ className, onClick }: PrevArrowProp) {
  return <button type='button' onClick={onClick} className={className} style={{ zIndex: '999', overflow: 'hidden' }}> <i className='arrow left-arrow' /></button>
}

const SanityCarouselBlock = ({ quotes, carouselText }: Queries.SanityCarousel) => {
  const settings = {
    focusOnSelect: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1290,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }

      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },

      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          nextArrow: null,
          prevArrow: null,
        }
      }
    ],
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  return (
    <Section className='mt-5 py-5'>
      <div className='carousel-container'>
        <div className='my-5 pb-5'>
          <SanityPortableText {...carouselText} />
        </div>
        <Slider {...settings} className='row justify-content-center'>
          {quotes?.map(({ id, name, _rawFeedback: rawFeedback, designation, team, companyImage, actionComponent }) => (
            <div className='col h-100' key={id} >
              <div className='card-block h-100 px-5 pt-5 pb-0'>
                <GatsbyImage className='mb-5' image={companyImage?.asset?.gatsbyImageData} alt={team} style={{ objectFit: 'fill' }} />
                <div className='feedback'>
                  <PortableText blocks={rawFeedback} />
                </div>
                <div className='mb-2 mt-2'>
                  <p style={{ marginBottom: '0', fontWeight: 'normal', color: '#353637' }}>{name}</p>
                  <p style={{ marginTop: '0', fontWeight: 'normal', color: '#353637' }}>{designation}</p>
                </div>
               {actionComponent && <div style={{ position: 'absolute', bottom: '10%', top: 'auto', display: 'inline' }}>
                  <SanityWithInAction  {...actionComponent} />
                </div>}
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </Section>
  )
}
export default SanityCarouselBlock

export const query = graphql`
fragment SanityCarousel on SanityCarousel {
  id
  oneLine
  quotes {
    id
    name
    designation
    team
    _rawFeedback
    companyImage {
      asset {
        gatsbyImageData(height: 40, placeholder: NONE)
      }
    }
    userImage {
      asset {
        gatsbyImageData
      }
    }
    actionComponent
    {
		...SanityActionComponent
    }
  }
  carouselText {
    ...SanityTextBlock
  }
}
`
