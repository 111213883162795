import React, { useState } from 'react';
import { graphql } from 'gatsby';
import SanityPortableText from '../shared/SanityPortableText';


const Accordion = ({ title, ...rawText }: Queries.SanityTextBlock) => {
  const [isActive, setIsActive] = useState(false);
  return <div className='table-cell'>
    <div className='accordion-item'>
      <div className='row justify-content-between accordion-cell' onClick={() => setIsActive(!isActive)} role="presentation">
        <div className='title'>{title}</div>
        <div className='right-0'>{isActive ? <i className='accordion-arrow up' /> : <i className='accordion-arrow down' />}</div>
      </div>

      {isActive && <div className='accordion-content'>
        <SanityPortableText title={title} {...rawText} />
      </div>}
    </div>
  </div>
}

const AccodionMenu = ({ text, accordion }: Queries.SanityAccordionMenu) =>
  <div className='container accordion mx-auto'>
    <div className='text'>{text.map(({ _id: id, ...texts }) => (
      <div className='text_center_xs mb-3' key={id}>
        <SanityPortableText _id={id} {...texts} />
      </div>
    ))}</div>
    <div className='d-flex flex-column'>
      {accordion.map(({ _id: id, ...item }) => (
        <Accordion _id={id} {...item} key={id} />

      ))
      }</div>
  </div>

export default AccodionMenu


export const query = graphql`
fragment SanityAccordionMenu on SanityAccordionMenu {
  id
  text {
    ...SanityTextBlock
  }
  accordion {
    ...SanityTextBlock
  }
}
`
